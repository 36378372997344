export const REGISTER_START = "REGISTER_START"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAIL = "REGISTER_FAIL"

export const LOGIN_START = "LOGIN_START"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"

export const LOGOUT_START = "LOGOUT_START"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAIL = "LOGOUT_FAIL"

export const SET_USER = "SET_USER";

export const ADD_TO_BASKET = "ADD_TO_BASKET"
export const REMOVE_FROM_BASKET = "REMOVE_FROM_BASKET"
export const SET_BASKET_EMPTY = "SET_BASKET_EMPTY"